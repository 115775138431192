import React from 'react'
import LayoutPrincipal from '../../components/layouts/LayoutPrincipal';
import CardElectronica from '../../components/ui/cardContenido/CardElectronica';
import imgFuente from '../../img/img_fuente.jpg';
import CardTexto from '../../components/ui/cardContenido/CardTexto';

// Data de la sesión de electrónica
const dataTutoElectronica = [
  {
    id: 'ek96hXNz8',
    imgPortada: imgFuente,
    title: 'Como convertir una fuente ATX en una fuente de voltaje para nuestro laboratorio',
    description: 'Convertiremos una fuente ATX en una fuente de voltaje que nos será de mucha ayuda en nuestro laboratorio.',
    date: '14 Agosto, 2023',
    to: 'https://www.youtube.com/watch?v=uT6JVkDuIdI',
    solotext: false,
    contenido : [
      {
        title: 'Fuente ATX',
        data: 'Una fuente de alimentación ATX, es un tipo de fuente de alimentación estándar ampliamente utilizada en computadoras de escritorio. \n \n Proporciona múltiples voltajes de salida que son necesarios para alimentar los diferentes componentes de una computadora, como la placa base, discos duros, unidades ópticas, tarjetas gráficas, entre otros.'
      },
      {
        title: 'Modificación',
        data: 'En este video les enseño el paso a paso para reutilizar una fuente ATX y convertirla en una fuente para nuestro laboratorio con diversas salidas de voltaje como los son: +3.3V, +5V, -5V, +12V, -12V. \n \n Con una de estas salidas y especificamente la de +12V se puede hacer la configuración para agregarle un regulador de voltaje LM317 y tener otra opción para nuestra fuente de laboratorio. '
      }
    ]
  },
]

const FuenteAtx = () => {
  return (
    <LayoutPrincipal titlePage='Fuentes ATX'>
      <div style={{ width: '100%', height: '100%'}}>
        { dataTutoElectronica.map((c, index) => (
          c.solotext ? <CardTexto path={ '/fuente-atx/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } body={ c.body } to={ c.to } alt={ c.title } contenido={ c.contenido }/> : 
          <CardElectronica path={ '/fuente-atx/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } imgPortada={ c.imgPortada } to={ c.to } alt={ c.title } contenido={ c.contenido }/>
        ))}
      </div>
    </LayoutPrincipal>
  )
}

export default FuenteAtx;
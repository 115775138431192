import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IoEye, IoLockClosed, IoMegaphone, IoUnlink } from "react-icons/io5";
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <IoLockClosed size={ 22 } />,
    title: 'Privacidad y seguridad de datos',
    description:
      'Tome el control total de sus datos de correo electrónico, Nuestras prácticas de seguridad son las mejores en su clase y garantiza que sus datos le pertenezcan solo a usted.',
  },
  {
    icon: <IoEye size={ 22 } />,
    title: 'Visibilidad de marca',
    description:
      'Cuando un cliente potencial vea su dirección de correo electrónico, podrá encontrar su empresa rápidamente con solo buscar el nombre del dominio.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Credibilidad',
    description:
      'Una dirección de correo electrónico que contiene el nombre de la empresa se ve más oficial y confiable que una que no cuenta con un dominio propio.',
  },
  {
    icon: <IoUnlink size={ 24 } />,
    title: 'Consistencia',
    description:
      'A medida que la fuerza laboral aumente, podrá estandarizar direcciones de correo electrónico y crear direcciones grupales consistentes para las funciones, como asistencia@sudominio.com.',
  },
  {
    icon: <IoMegaphone size={ 22 } />,
    title: 'Marketing de marca',
    description:
      'A pesar de que existen diversas maneras de comercializar el nombre de su empresa, contar con un correo con dominio propio es la más sencilla.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Obtenga asistencia en cualquier momento',
    description:
    <>
      Siempre estamos aquí para ayudarlo. Gracias a nuestro soporte técnico ininterrumpido, tenga la tranquilidad de que jamás se quedará sin respuestas. Escríbanos en cualquier momento a <br />
      <a href="mailto:alexis.uribe@tecnoalexis.com" style={{ color: '#007BFF', textDecoration: 'none' }}>alexis.uribe@tecnoalexis.com</a>
    </>
  },
];

export default function Beneficios() {
  return (
    <Box
      id="sesion_beneficios"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 10, sm: 20 },
        color: 'white',
        bgcolor: '#FFFDE7',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography 
            component="h3" 
            variant="h2" 
            gutterBottom 
            style={{ fontFamily: 'roboto', color: '#000', fontSize: 30 }} 
            sx={{
              marginTop: { sm: 0, xs: 8 }
            }}
          >
            Beneficios
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.500' }} style={{ fontFamily: 'roboto', fontSize: 18 }}>
            Configuramos correos corporativos con tu dominio propio, asegurando seguridad, personalización y fácil gestión.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={ index }>
              <Stack
                direction="column"
                component={ Card }
                spacing={ 1 }
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }} style={{ fontFamily: 'roboto', fontSize: 18 }}>
                    { item.title }
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }} style={{ fontFamily: 'roboto', lineHeight: 1.6 }}>
                    { item.description }
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MuiChip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';

import ImgCaracteristicas from '../../../../img/img_caracteristicas_email.png';

const items = [
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Mail en su dispositivo móvil',
    description:
      'Gestione sus comunicaciones empresariales, incluso cuando no esté en su escritorio. Las aplicaciones móviles nativas para Mail, Mail Admin y Streams permiten que logre mantenerse en contacto desde cualquier lugar fácilmente y sin esfuerzo.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Un amplio panel de control',
    description:
      'El potente panel de control de Zoho Mail es el espacio desde el cual el administrador puede acceder a todos los ajustes, configuraciones y personalizaciones. Agregue usuarios, gestione alias de grupo, configure políticas para moderar el contenido de correos empresariales y mucho más.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Seguridad y privacidad sin precedentes',
    description:
      'Los centros de datos de Zoho Mail cuentan con seguridad y vigilancia de primer nivel, con un tiempo de actividad confiable del 99,9 %. Benefíciese de un hosting de correo electrónico seguro que admite encriptado en reposo y en tránsito, junto con encriptado de mensajes S/MIME.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
];

const Chip = styled( MuiChip )(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: 'flex', sm: 'none' },
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, overflow: 'auto' }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={ () => handleItemClick(index) }
            selected={ selectedItemIndex === index }
            style={{ fontFamily: 'roboto' }}
          />
        ))}
      </Box>

      <Card variant="outlined">
        <Box
          sx={{
            mb: 2,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: 280,
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${ ImgCaracteristicas })`
          }}
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: 'text.primary', fontWeight: 'medium' }}
            style={{ fontFamily: 'roboto' }}
          >
            { selectedFeature.title }
          </Typography>

          <Typography 
            variant="body2" 
            sx={{ color: 'text.secondary', 
            mb: 1.5 }} 
            style={{ fontFamily: 'roboto', lineHeight: 1.6 }}>
              { selectedFeature.description }
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

MobileLayout.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.element,
    imageDark: PropTypes.string.isRequired,
    imageLight: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
};

export { MobileLayout };

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="sesion_caracteristicas" sx={{ py: { xs: 3, sm: 16 } }}>
      <Box sx={{ width: { sm: '100%', md: '60%' } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary', marginTop: { sm: 0, xs: 8 } }}
          style={{ fontFamily: 'roboto', fontSize: 30 }}
        >
          Características
        </Typography>

        <Typography
          variant="body1"
          sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
          style={{ fontFamily: 'roboto', fontSize: 18 }}
        >
          Estas son las características más importantes de un correo electrónico profesional
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row-reverse' },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 2,
              height: '100%',
              width: '100%'
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: '100%',
                    width: '100%',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: 'action.selected',
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      gap: 1,
                      textAlign: 'left',
                      textTransform: 'none',
                      color: 'text.secondary',
                    },
                    selectedItemIndex === index && {
                      color: 'text.primary',
                    },
                  ]}
                >
                  <Typography 
                    variant="h6"
                    style={{ fontFamily: 'roboto', fontSize: 22, fontWeight: 700 }}
                  >
                      { title }
                  </Typography>

                  <Typography 
                    variant="body2"
                    style={{ fontFamily: 'roboto', lineHeight: 1.6 }}
                  >
                    { description }
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </div>
        
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: { xs: '90%', md: '100%' },
            height: 'var(--items-image-height)'
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: '100%',
                height: '100%',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${ ImgCaracteristicas })`
              }}
              style={{ }}
            />
            
          </Card>
        </Box>
      </Box>
    </Container>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import '../styles.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';


const items = [
  {
    title: '.com',
    description:
      'Inspira confianza con este dominio reconocido.',
  },
  {
    title: '.online',
    description:
      'Es una gran alternativa a .com: genérico y universal.',
  },
  {
    title: '.net',
    description:
      'Muestra tu experiencia con un dominio .net',
  },
  {
    title: '.io',
    description:
      'Crea una conexión con la industria tecnológica.',
  },
  {
    title: '.cloud',
    description:
      'Elige .cloud si tu negocio está en la nube.',
  },
  {
    title: '.xyz',
    description: 'Un dominio único y popular para tu negocio de éxito.'
  },
];

export default function Dominios() {
  return (
    <Container
      id="sesion_dominios"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary', marginTop: { sm: 0, xs: 8 } }}
          style={{ fontFamily: 'roboto', fontSize: 30 }}
        >
          Necesitas un dominio <span style={{ color: '#5025D1', fontWeight: '800', fontSize: 34 }}>.com </span> para tu comercio? 
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }} style={{ fontFamily: 'roboto', fontSize: 18 }}>
          Te ayudamos a encontrar el dominio perfecto con el nombre de tu empresa, con uno de los mejores proveedores de dominios del mercado. 
        </Typography>
      </Box>

      <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={ index }>
              <Stack
                className='stackCard'
                direction="column"
                component={ Card }
                spacing={ 1 }
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: '#fff',
                }}
              >
                <div>
                  <Typography gutterBottom sx={{ fontWeight: '600' }} style={{ fontFamily: 'roboto', fontSize: 28 }}>
                    { item.title }
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#2F1C6A', fontWeight: '400' }} style={{ fontFamily: 'roboto', fontSize: 16, lineHeight: 1.6 }}>
                    { item.description }
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            useFlexGap
            sx={{ pt: 3, width: { xs: '100%', sm: '350px' } }}
            style={{ fontFamily: 'roboto', alignItems: 'center', alignContent: 'center', display: 'flex'}}
          >
            <Link
              to={'https://forms.zohopublic.com/alexisu/form/SoftwareRequest/formperma/gAVaKo8IXLnJPqlFwkQlP0ofk_3sumTU7X0xEqo9RF8'}
            >
              <Button
                sx={{ minWidth: 'fit-content' }}
                style={{ fontFamily: 'roboto', width: 300, backgroundColor: '#5025D1', color: '#fff' }}
              >
                Solicitar información
              </Button>    
            </Link>
          </Stack>
      
    </Container>
  );
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import iconoRam from '../../img/icono_ram.png';
import iconoCodigo from '../../img/icono_codigo.png';
import iconoUsuario from '../../img/usuario.png';
//import iconoAws from '../../img/icono_aws.png';
import iconoGranero from '../../img/icono_granero.png';
import { IoGridOutline } from "react-icons/io5";
import { Menu } from 'antd';

    function getItem(label, key, icon, children, disable) {
        return {
            label,
            key,
            icon,
            children,
            disable
        };
    }

    // submenu keys of first level
    const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

const MenuUi = ({ clasname_ }) => {

    const navigate = useNavigate();

    const [openKeys, setOpenKeys] = useState('');

    const onOpenChange = ( keys ) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if ( latestOpenKey && rootSubmenuKeys.indexOf( latestOpenKey ) === -1) {
            setOpenKeys( keys );
        } else {
            setOpenKeys( latestOpenKey ? [ latestOpenKey ] : []);
        }
    };

    const itemSeleccionado = (e) => {
        navigate( e.key );
    };

    const items = [
        getItem('Electrónica', 'sub1', <img src={ iconoRam } alt='Icono de electrónica' width={ 20 } height={ 20 }/>, 
            [
                getItem('Amplificadores Op.', '/amplificador-operacional'),
                getItem('Regulador LM317', '/regulador-voltaje'),
                getItem('Fuente ATX', '/fuente-atx'),
            ], true
        ),
        getItem('Software', 'sub2', <img src={ iconoCodigo } alt='Icono de Código' width={ 20 } height={ 20 }/>, 
            [
                getItem('Visual Studio Code', '/visual-studio-code'),
                getItem('Excel en React', '/excel-react'),
            ], true
        ),/*
        getItem('AWS', 'sub3', <img src={ iconoAws } alt='Imagen de AWS' width={ 20 } height={ 20 }/>, [
        getItem('Funciones Lambda', '/aws-lambda'),
        getItem('Cognito', '/aws-cognito'),
        getItem('Amplify', '/aws-amplify'),
    ]),*/
        getItem('Mi granja', 'sub4', <img src={ iconoGranero } alt='Icono de Mi granaja' width={ 20 } height={ 20 }/>, 
            [
                getItem('Gallinas Ponedoras', '/gallinas-ponedoras'),
            ], true
        ),
        //Condición para mostrar el item cuando esté en modo móvil
        ( window.screen.width <= 800 ? 
            getItem('Servicios', 'sub5', <IoGridOutline style={{ width: 20, height: 20 }}/>, 
                [
                    getItem('Correos Corporativos', '/servicios/correos-corporativos')
                ], false
            ) : ''
        ),
        ( window.screen.width <= 800 ? 
            getItem('Sobre mí', 'sub6', <img src={ iconoUsuario } alt='' width={ 20 } height={ 20 }/>, 
                [
                    getItem('Sobre mí', '/sobre-mi')
                ], false
            ) : ''
        )
    ];

    return (
        <Menu
            className={ clasname_ }
            mode="inline"
            openKeys={ openKeys }
            onOpenChange={ onOpenChange }
            items={ items }
            onClick={ itemSeleccionado }
        />
  )
}

export default MenuUi;
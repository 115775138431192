import React from 'react'
import LayoutPrincipal from '../../components/layouts/LayoutPrincipal';
import CardElectronica from '../../components/ui/cardContenido/CardElectronica';
import imgVisuals from '../../img/vs.png';
import CardTexto from '../../components/ui/cardContenido/CardTexto';

// Data de la sesión de electrónica
const dataDesarrollo = [
  {
    id: 'j6s4udnd10f56j7985nf4lf3',
    imgPortada: imgVisuals,
    title: 'Como instalar Visual Studio Code',
    description: 'Visual Studio Code es un editor de código fuente desarrollado por Microsoft.',
    date: '24 Junio, 2023',
    to: 'https://www.youtube.com/watch?v=xHD6cYfDp4o',
    solotext: false,
    contenido : [
    ]
  },
]

const VisualStudioCode = () => {
  return (
    <LayoutPrincipal titlePage='Visual Studio Code'>
      <div style={{ width: '100%', height: '100%'}}>
        { dataDesarrollo.map((c, index) => (
          c.solotext ? <CardTexto path={ '/visual-studio-code/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } body={ c.body } to={ c.to } alt={ c.title } contenido={ c.contenido }/> : 
          <CardElectronica path={ '/visual-studio-code/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } imgPortada={ c.imgPortada } to={ c.to } alt={ c.title } contenido={ c.contenido }/>
        ))}
      </div>
    </LayoutPrincipal>
  )
}

export default VisualStudioCode;
import React from 'react';
import { Link } from 'react-router-dom';
import logoLinks from '../../img/logo_links.png';

const BodyLinks = () => {
  return (
    <>
        <div style={{ paddingTop: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

            <div style={{ marginBottom: '20px'}}>
                <img
                    src={ logoLinks }
                    alt="Imagen del banner" 
                    width={ 180 } 
                    height={ 180 }
                />
            </div>
            
            <div>
                <div class="hover:opacity-85 lg:min-w-max flex items-center sm:w-max w-full lg:pb-0 border-b border-gray-100 lg:border-0 px-6 lg:px-0">
                    <Link  to="/"
                        title='Sitio Web'
                        style={{ backgroundColor: '#ffffff', borderRadius: '10px', width: '320px' }}
                        class="flex justify-center items-center w-full sm:w-max px-6 h-12 rounded-full outline-none relative overflow-hidden border duration-300 ease-linear
                        after:absolute after:inset-x-0 after:aspect-square after:scale-0 after:opacity-70 after:origin-center after:duration-300 after:ease-linear 
                        after:rounded-full after:top-0 after:left-0 after:bg-[#172554]  bg-blue-600">
                            <span class="relative z-10 text-black">Sitio Web</span>
                    </Link>
                </div>

                <div class="hover:opacity-85 lg:min-w-max flex items-center sm:w-max w-full lg:pb-0 border-b border-gray-100 lg:border-0 px-6 lg:px-0" 
                    style={{ marginTop: '10px' }}
                >
                    <Link  to="/servicios/correos-corporativos"
                        title='Correos Corporativos'
                        style={{ backgroundColor: '#ffffff', borderRadius: '10px', width: '320px' }}
                        class="flex justify-center items-center w-full sm:w-max px-6 h-12 rounded-full outline-none relative overflow-hidden border duration-300 ease-linear
                        after:absolute after:inset-x-0 after:aspect-square after:scale-0 after:opacity-70 after:origin-center after:duration-300 after:ease-linear 
                        after:rounded-full after:top-0 after:left-0 after:bg-[#172554]  bg-blue-600">
                            <span class="relative z-10 text-black">Correos Corporativos</span>
                    </Link>
                </div>

                <div class="hover:opacity-85 lg:min-w-max flex items-center sm:w-max w-full lg:pb-0 border-b border-gray-100 lg:border-0 px-6 lg:px-0"
                    style={{ marginTop: '10px' }}>
                    <a  href="http://www.youtube.com/@TecnoAlexis"
                        title='YouTube'
                        target='_blank'
                        rel='noreferrer'
                        style={{ backgroundColor: '#fff', borderRadius: '10px', width: '320px' }}
                        class="flex justify-center items-center w-full sm:w-max px-6 h-12 rounded-full outline-none relative overflow-hidden border duration-300 ease-linear
                            after:absolute after:inset-x-0 after:aspect-square after:scale-0 after:opacity-70 after:origin-center after:duration-300 after:ease-linear 
                            after:rounded-full after:top-0 after:left-0 after:bg-[#172554]  bg-blue-600">
                            <span class="relative z-10 text-black">YouTube</span>
                    </a>
                </div>

                <div class="hover:opacity-85 lg:min-w-max flex items-center sm:w-max w-full lg:pb-0 border-b border-gray-100 lg:border-0 px-6 lg:px-0"
                    style={{ marginTop: '10px' }}>
                    <a  href="https://www.tiktok.com/@tecnoalexis?is_from_webapp=1&sender_device=pc"
                        title='TikTok'
                        target='_blank'
                        rel='noreferrer'
                        style={{ backgroundColor: '#ffffff', borderRadius: '10px', width: '320px' }}
                        class="flex justify-center items-center w-full sm:w-max px-6 h-12 rounded-full outline-none relative overflow-hidden border duration-300 ease-linear
                            after:absolute after:inset-x-0 after:aspect-square after:scale-0 after:opacity-70 after:origin-center after:duration-300 after:ease-linear 
                            after:rounded-full after:top-0 after:left-0 after:bg-[#172554]  bg-blue-600">
                            <span class="relative z-10 text-black">TikTok</span>
                    </a>
                </div>

                <div class="hover:opacity-85 lg:min-w-max flex items-center sm:w-max w-full lg:pb-0 border-b border-gray-100 lg:border-0 px-6 lg:px-0"
                    style={{ marginTop: '10px' }}>
                    <a  href="https://www.facebook.com/profile.php?id=61551771311626&locale=es_LA"
                        title='Facebook'
                        target='_blank'
                        rel='noreferrer'
                        style={{ backgroundColor: '#ffffff', borderRadius: '10px', width: '320px' }}
                        class="flex justify-center items-center w-full sm:w-max px-6 h-12 rounded-full outline-none relative overflow-hidden border duration-300 ease-linear
                            after:absolute after:inset-x-0 after:aspect-square after:scale-0 after:opacity-70 after:origin-center after:duration-300 after:ease-linear 
                            after:rounded-full after:top-0 after:left-0 after:bg-[#172554]  bg-blue-600">
                            <span class="relative z-10 text-black">Facebook</span>
                    </a>
                </div>

                <div class="hover:opacity-85 lg:min-w-max flex items-center sm:w-max w-full lg:pb-0 border-b border-gray-100 lg:border-0 px-6 lg:px-0"
                    style={{ marginTop: '10px' }}>
                    <a  href="https://www.instagram.com/tecnoalexiss"
                        title='Instagram'
                        target='_blank'
                        rel='noreferrer'
                        style={{ backgroundColor: '#ffffff', borderRadius: '10px', width: '320px' }}
                        class="flex justify-center items-center w-full sm:w-max px-6 h-12 rounded-full outline-none relative overflow-hidden border duration-300 ease-linear
                            after:absolute after:inset-x-0 after:aspect-square after:scale-0 after:opacity-70 after:origin-center after:duration-300 after:ease-linear 
                            after:rounded-full after:top-0 after:left-0 after:bg-[#172554]  bg-blue-600">
                            <span class="relative z-10 text-black">Instagram</span>
                    </a>
                </div>

                <div class="hover:opacity-85 lg:min-w-max flex items-center sm:w-max w-full lg:pb-0 border-b border-gray-100 lg:border-0 px-6 lg:px-0"
                    style={{ marginTop: '10px' }}>
                    <a  href="https://www.paypal.com/paypalme/AlexisUribe794"
                        title='Invítame a un Café'
                        target='_blank'
                        rel='noreferrer'
                        style={{ backgroundColor: '#ffffff', borderRadius: '10px', width: '320px' }}
                        class="flex justify-center items-center w-full sm:w-max px-6 h-12 rounded-full outline-none relative overflow-hidden border duration-300 ease-linear
                            after:absolute after:inset-x-0 after:aspect-square after:scale-0 after:opacity-70 after:origin-center after:duration-300 after:ease-linear 
                            after:rounded-full after:top-0 after:left-0 after:bg-[#172554]  bg-blue-600">
                            <span class="relative z-10 text-black">Invítame a un Café</span>
                    </a>
                </div>
            </div>

            <div>
                <p class="mt-10 text-xs text-gray-800">© 2024 TecnoAlexis Todos los derechos reservados</p>
            </div>

        </div>
    </>
  )
}

export default BodyLinks;
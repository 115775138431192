import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Links from './pages/links';
import NotFound from './pages/404';
import AmplificadoresOperacionales from './pages/electronica/amplificadoresOp';
import ReguladorVoltaje from './pages/electronica/reguladorVoltaje';
import FuenteAtx from './pages/electronica/fuenteAtx';
import VisualStudioCode from './pages/desarrollo_software/visualStudioCode';
import ExcelReact from './pages/desarrollo_software/excelReact';
import GallinasPonedoras from './pages/mi_granja/gallinasPonedoras';
import SobreMi from './pages/sobre_mi';
import ContenidoElectronica from './pages/electronica/contenido';
import ContenidoDesarrolloSoft from './pages/desarrollo_software/contenido';
import ContenidoHome from './pages/home/contenido';
import CorreosEmpresariales from './pages/servicios/marketing_page/MarketingPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={ <Home /> } />
        <Route path="/contenido/:id" exact element={ <ContenidoHome /> } />
        <Route path="/amplificador-operacional" element={ <AmplificadoresOperacionales /> } />
        <Route path="/amplificador-operacional/contenido/:id" element={ <ContenidoElectronica /> } />
        <Route path="/regulador-voltaje" element={ <ReguladorVoltaje /> } />
        <Route path="/regulador-voltaje/contenido/:id" element={ <ContenidoElectronica /> } />
        <Route path="/visual-studio-code" element={ <VisualStudioCode /> } />
        <Route path="/visual-studio-code/contenido/:id" element={ <ContenidoDesarrolloSoft /> } />
        <Route path="/excel-react" element={ <ExcelReact /> } />
        <Route path="/excel-react/contenido/:id" element={ <ContenidoDesarrolloSoft /> } />
        <Route path="/fuente-atx" element={ <FuenteAtx /> } />
        <Route path="/fuente-atx/contenido/:id" element={ <ContenidoElectronica /> } />
        <Route path="/links" element={ <Links /> } />
        <Route path="/sobre-mi" element={ <SobreMi /> } />
        <Route path="/gallinas-ponedoras" exact element={ <GallinasPonedoras /> } />
        <Route path="/servicios/correos-corporativos" element={ <CorreosEmpresariales /> } />
        {/* Error de url */}
        <Route path="*" element={ <NotFound /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react'
import LayoutPrincipal from '../../components/layouts/LayoutPrincipal';
import CardElectronica from '../../components/ui/cardContenido/CardElectronica';
import imgLm317 from '../../img/miniatura_lm317.jpg';
import CardTexto from '../../components/ui/cardContenido/CardTexto';

// Data de la sesión de electrónica
const dataTutoElectronica = [
  {
    id: 'eIv5He87J',
    imgPortada: imgLm317,
    title: 'Cálculos teóricos para el componente electrónico LM317T',
    description: 'En este video les explico como hacer el cálculo para las resistencias de la configuración de un LM317T como regulador de voltaje.',
    date: '27 Enero, 2024',
    to: 'https://www.youtube.com/watch?v=U2yXcu5zpdU',
    solotext: false,
    contenido : [
      {
        title: 'Regulador de voltaje LM317',
        data: 'El LM317 es un regulador de voltaje ajustable bastante utilizado en circuitos electrónicos con el que se obtiene un voltaje de salida estable y regulable. \n \n Este componente es especialmente útil en aplicaciones donde se necesita un voltaje específico que no se puede obtener directamente de una fuente de alimentación común.'
      },
      {
        title: 'Características del LM317',
        data: 'Voltaje de salida: Este regulador permite ajustar un voltaje desde 1.25V hasta 37V pero esto depende de la configuración con las resistencias en el circuito. \n \n Corriente de salidad máxima: Este componente puede suministrar hasta un máximo de 1.5A pero esto depende de la disipación del calor y el voltaje de entrada. \n \n Protección Interna: Incluye protecciones contra sobrecarga, sobrecalentamiento y corriente excesiva, lo que lo hace robusto y confiable.'
      }
    ]
  },
]

const ReguladorVoltaje = () => {
  return (
    <LayoutPrincipal titlePage='Reguladores de Voltaje'>
      <div style={{ width: '100%', height: '100%'}}>
        { dataTutoElectronica.map((c, index) => (
          c.solotext ? <CardTexto path={ '/regulador-voltaje/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } body={ c.body } to={ c.to } alt={ c.title } contenido={ c.contenido }/> : 
          <CardElectronica path={ '/regulador-voltaje/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } imgPortada={ c.imgPortada } to={ c.to } alt={ c.title } contenido={ c.contenido }/>
        ))}
      </div>
    </LayoutPrincipal>
  )
}

export default ReguladorVoltaje;
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { IoLogoYoutube, IoMenuOutline } from "react-icons/io5";
import imgLogo from '../../img/logo_tecnoalexis_1.png';

const HeaderUi = ({ showDrawer }) => {
    return (
        <header>
            <div className='containerHeader'>
                <div className='logo'>
                    <Button className='btnMenu' onClick={ () => showDrawer()} title='Abrir menú' icon={ <IoMenuOutline className='iconMenu'/> }></Button>
                    <Link to="/" className='titleVisible'>
                        <img className='img_logo' src={ imgLogo } alt='logo' style={{ width: 200, cursor: 'pointer' }}/>
                    </Link>
                </div>

                <div className='buscador'>
                    <Link to="/" className='titleVisible2'>
                        <img className='img_logo' src={ imgLogo } alt='logo' style={{ width: 200, cursor: 'pointer' }}/>
                    </Link>
                </div>

                <div className='divBotones'>
                    <Link to='http://www.youtube.com/@TecnoAlexis' target='_blank'>
                        <Button className='btnYoutube' shape="round" title='Ir a YouTube' icon={<IoLogoYoutube className='iconYoutube'/>}>YouTube</Button>
                    </Link>
                </div>
            </div>
        </header>
    )
}

export default HeaderUi
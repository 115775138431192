import React from 'react';
import { useLocation } from 'react-router-dom';
import './styles.css';
import LayoutPrincipalContenido from '../../components/layouts/LayoutPrincipalContenido';

const Contenido = () => {
    //const { id } = useParams();
    const location = useLocation();

    // En esta parte se hace la validación si vienen datos de la card, si no vienen datos indica que 
    // hay que buscar la información con el parámetro que viene del { id }

    return (
        <LayoutPrincipalContenido titlePage={ location.state.title } titleCenter={ true } fecha={ location.state.date }>
            <div style={{ width: '100%', height: '100%'}}>
                <div>
                    { location.state.imgPortada && (
                        <div className='divContainerImagenElectronicaContenido'>
                            <img 
                                className='imgCardElectronicaContenido w-full object-cover' 
                                src={ location.state.imgPortada } 
                                alt={ location.state.alt } 
                            />
                        </div>
                    )}

                    { location.state.contenido.length === 0 ? (
                        <p className='pTextoContenido' style={{ whiteSpace: 'pre-line' }}>{ location.state.description }</p>
                    ): ''}
                        
                    { location.state.contenido.map((item, index) => (
                        <div className='divTextoContenido'>
                            { item.title && <h2 className='subTitleContenido'>{ item.title }</h2> }
                            <p className='pTextoContenido' style={{ whiteSpace: 'pre-line' }}>{ item.data }</p>
                        </div>
                    ))}            
                </div>
            </div>
        </LayoutPrincipalContenido>
        
    )
}

export default Contenido;
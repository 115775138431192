import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import imgLogoMail from '../../../../img/logo_tecnoalexis_1_mail.png';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 3 }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <ScrollLink to="hero" smooth={ true } duration={ 500 }>
              <img className='img_logo' src={ imgLogoMail } alt='logo' style={{ width: 160, cursor: 'pointer', marginRight: 15 }}/>
            </ScrollLink>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              
              <ScrollLink to="sesion_caracteristicas" smooth={ true } duration={ 500 }>
                <Button variant="text" color="info" size="small" style={{ fontFamily: 'roboto'}}>
                  Características
                </Button>
              </ScrollLink>
              
              <ScrollLink to="sesion_beneficios" smooth={ true } duration={ 500 }>
                <Button variant="text" color="info" size="small" style={{ fontFamily: 'roboto'}}>
                  Beneficios
                </Button>
              </ScrollLink>

              <ScrollLink to="sesion_dominios" smooth={ true } duration={ 500 }>
                <Button variant="text" color="info" size="small" style={{ fontFamily: 'roboto'}}>
                  Dominios
                </Button>
              </ScrollLink>

              <ScrollLink to="sesion_precio" smooth={ true } duration={ 500 }>
                <Button variant="text" color="info" size="small" style={{ fontFamily: 'roboto'}}>
                  Precios
                </Button>
              </ScrollLink>
              
              <ScrollLink to="sesion_preguntas_frec" smooth={ true } duration={ 500 }>
                <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }} style={{ fontFamily: 'roboto'}}>
                  FAQ
                </Button>
              </ScrollLink>
            
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Link to='/'>
              <Button color="primary" variant="contained" size="small" style={{ fontFamily: 'roboto'}}>Volver a TecnoAlexis</Button>
            </Link>
          </Box>

          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={ toggleDrawer(true) }>
              <MenuIcon />
            </IconButton>

            <Drawer anchor="top" open={open} onClose={ toggleDrawer(false) }>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>

                <Divider sx={{ my: 3 }} />

                <MenuItem style={{ fontFamily: 'roboto'}}>
                  <ScrollLink to="sesion_caracteristicas" smooth={ true } duration={ 500 }>
                    Características
                  </ScrollLink>
                </MenuItem>

                <MenuItem style={{ fontFamily: 'roboto'}}>
                  <ScrollLink to="sesion_beneficios" smooth={ true } duration={ 500 }>
                    Beneficios
                  </ScrollLink>
                </MenuItem>

                <MenuItem style={{ fontFamily: 'roboto'}}>
                  <ScrollLink to="sesion_dominios" smooth={ true } duration={ 500 }>
                      Dominios
                  </ScrollLink>
                </MenuItem>

                <MenuItem style={{ fontFamily: 'roboto'}}>
                  <ScrollLink to="sesion_precio" smooth={ true } duration={ 500 }>
                    Precios
                  </ScrollLink>
                </MenuItem>

                <MenuItem style={{ fontFamily: 'roboto'}}>
                  <ScrollLink to="sesion_preguntas_frec" smooth={ true } duration={ 500 }>
                    FAQ
                  </ScrollLink>
                </MenuItem>
                
                <MenuItem>
                  <Link to='/' style={{ width: '100%'}}>
                    <Button color="primary" variant="contained" fullWidth style={{ fontFamily: 'roboto'}}>
                      Volver a TecnoAlexis
                    </Button>
                  </Link>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}

import React from 'react';
import './styles.css';
import LayoutPrincipal from '../../components/layouts/LayoutPrincipal';

const Sobre_mi = () => {
  return (
    <LayoutPrincipal titlePage='Sobre mí' titleCenter={ true }>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <h1 style={{ fontSize: '16px', fontWeight: '600', fontFamily: "Poppins", textAlign: 'justify'}}
            >HOLA, MI NOMBRE ES ALEXIS Y SOY DESARROLLADOR DE SOFTWARE, INGENIERO MECATRÓNICO EN PROCESO Y AHORA CREADOR DE CONTENIDO.</h1>
        </div>

        <div style={{ width: '100%', marginTop: '20px', fontFamily: "Roboto", fontWeight: '500', textAlign: 'justify', color: '#697074', fontSize: '17px'}}>
            <p>Soy de la ciudad de la eterna primavera, Medellín. Desde muy pequeño me he interezado por todo lo que tiene que ver con la ciencia y la tecnología.</p>
        </div>

        <div style={{ width: '100%', marginTop: '20px', fontFamily: "Roboto", fontWeight: '500', textAlign: 'justify', color: '#697074', fontSize: '17px'}}>
            <p>Hace un tiempo atrás tuve la idea de crear una marca muy personal y es por esto que he creado <span style={{ fontWeight: '500', color: '#000' }}>TECNOALEXIS, </span> 
             con el objetivo de compartir mi conocimiento aquellas personas que están interezadas en aprender sobre electrónica, robótica, sistemas de control y desarrollo de software.
            </p>
        </div>

        <div style={{ width: '100%', marginTop: '20px', fontFamily: "Roboto", fontWeight: '500', textAlign: 'justify', color: '#697074', fontSize: '17px'}}>
            <p>Cabe aclarar que no soy el más experto en estos temas, pero si estoy motivado a mejorar y transmitir mi conocimiento de la mejor forma posible. Por eso estoy muy al pendiente de las notas y observaciones que me 
              dejan en las diferentes redes sociales donde comparto mi contenido.
            </p>
        </div>

        <div style={{ width: '100%', marginTop: '20px', fontFamily: "Roboto", fontWeight: '500', textAlign: 'justify', color: '#697074', fontSize: '17px'}}>
            <p>Este sitio web por el momento es algo básico y con muy poco contenido ya que va ha ir creciendo con el tiempo. Hay que entender que cada contenido 
              conlleva una serie de pasos para llevarlo a cabo, y es mejor crear un contenido de calidad para los interezados y no un contenido vago, por lo que su proceso va hacer lento.
            </p>
        </div>

        <div style={{ width: '100%', marginTop: '20px', fontFamily: "Roboto", fontWeight: '500', textAlign: 'justify', color: '#697074', fontSize: '17px'}}>
            <p>Gracias por hacer parte de mi comunidad <span style={{ fontWeight: '500', color: '#000' }}>TECNOALEXIS. </span></p>
        </div>
    </LayoutPrincipal>
  )
}

export default Sobre_mi;
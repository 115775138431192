import React, { useState } from 'react';
import './styleLayoutPrincipal.css';
import { IoLogoYoutube, IoLogoFacebook, IoLogoTiktok, IoLogoInstagram, IoPerson, IoMail } from "react-icons/io5";
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import MenuUi from '../ui/MenuUi';
import DrawerMenu from '../ui/DrawerMenu';
import HeaderUi from '../ui/HeaderUi';

const LayoutPrincipal = ({ children, titlePage, titleCenter = false }) => {

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };
      
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <DrawerMenu clasname_='drawerMenu' onClose={ onClose } open={ open }/>

            <HeaderUi showDrawer={ () => showDrawer() }/>

            <div className='divMain'>
                <div className='divMenuLateralIzquierdo'>

                    <MenuUi clasname_='menuLateralDerecho'/>

                    <div className='divFooterMenu' style={{ justifyContent: ''}}>
                        <h2 className='titleFooterMenu'>© 2024 TecnoAlexis Todos los derechos reservados</h2>
                    </div>
                </div>

                <div className='divMainChildren'>
                    <div style={{ marginBottom: '20px', display: 'flex', justifyContent: [ titleCenter ? 'center' : 'left'] }}>
                        <h1 style={{ fontSize: '35px', fontWeight: '800'}}>{ titlePage }</h1>
                    </div>
                    { children }
                </div>

                <div className='divMenuLateralDerecho'>
                    <div className='divContainerRedesSobreMi'>
                        <h2 className='h2TitleRedes'>SOBRE MÍ</h2>

                        <div className='divContainerBtnSobreMi'>
                            <Link to='/sobre-mi'>
                                <Button className='btnRedesSobreMi' title='Sobre mí' icon={<IoPerson style={{ color: '#000000'}}/>} >Sobre mí</Button>
                            </Link>
                        </div>
                    </div>

                    <div className='divContainerRedesSobreMi'>
                        <h2 className='h2TitleRedes'>SERVICIOS</h2>

                        <div className='divContainerBtnSobreMi'>
                            <Link to='/servicios/correos-corporativos'>
                                <Button className='btnRedesSobreMi' title='Sobre mí' icon={<IoMail style={{ color: '#000000'}}/>} >Correos Corporativos</Button>
                            </Link>
                        </div>
                    </div>

                    <div className='divContainerRedes'>
                        <h2 className='h2TitleRedes'>REDES SOCIALES</h2>

                        <div className='divContainerBtnRedes'>
                            <Link to='http://www.youtube.com/@TecnoAlexis' target='_blank'>
                                <Button className='btnRedes' title='Ir a YouTube' icon={<IoLogoYoutube className='iconRedesYoutube'/>} >YouTube</Button>
                            </Link>
                            <Link to='https://www.tiktok.com/@tecnoalexis?is_from_webapp=1&sender_device=pc' target='_blank'>
                                <Button className='btnRedes' title='Ir a TikTok' icon={<IoLogoTiktok style={{ color: '#000000'}}/>} >TikTok</Button>
                            </Link>
                            <Link to='https://www.instagram.com/tecnoalexiss' target='_blank'>
                                <Button className='btnRedes' title='Ir a Instagram' icon={<IoLogoInstagram style={{ color: '#EA0D63'}}/>} >Instagram</Button>
                            </Link>
                            <Link to='https://www.facebook.com/profile.php?id=61551771311626&locale=es_LA' target='_blank'>
                                <Button className='btnRedes' title='Ir a Facebook' icon={<IoLogoFacebook style={{ color: '#0467DF'}}/>} >Facebook</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default LayoutPrincipal;
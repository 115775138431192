import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import imgFondoMail from '../../../../img/img_mail_fondo.png';

import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '4px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${ imgFondoMail })`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${ imgFondoMail })`,
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 18 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: { sm: 45, xs: 37 }
            }}
            style={{ fontFamily: 'roboto', textAlign: 'center'}}
          >
            Eleva la profesionalidad de tu empresa con correos corporativos personalizados
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
            style={{ fontFamily: 'roboto'}}
          >
            Establece credibilidad y confianza con correos personalizados que representen tu marca.
          </Typography>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            useFlexGap
            sx={{ pt: 3, width: { xs: '100%', sm: '350px' } }}
            style={{ fontFamily: 'roboto', alignItems: 'center', alignContent: 'center', display: 'flex'}}
          >
            <Link
              to={'https://forms.zohopublic.com/alexisu/form/SoftwareRequest/formperma/gAVaKo8IXLnJPqlFwkQlP0ofk_3sumTU7X0xEqo9RF8'}
            >
              <Button
                sx={{ minWidth: 'fit-content' }}
                style={{ fontFamily: 'roboto', width: 300, backgroundColor: '#5025D1', color: '#fff' }}
              >
                Solicitar información
              </Button>    
            </Link>
          </Stack>

        </Stack>
        <StyledBox id="image" />
      </Container>
    </Box>
  );
}

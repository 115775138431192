import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
//import TwitterIcon from '@mui/icons-material/X';
import { IoLogoYoutube, IoLogoFacebook, IoLogoTiktok, IoLogoInstagram } from "react-icons/io5";

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <div>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }} style={{ fontFamily: 'roboto'}}>
            © 2024 TecnoAlexis Todos los derechos reservados
          </Typography>
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary' }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="http://www.youtube.com/@TecnoAlexis"
            aria-label="YouTube"
            target='_blank'
            sx={{ alignSelf: 'center' }}
            title='Ir a Youtube'
          >
            <IoLogoYoutube />
          </IconButton>

          <IconButton
            color="inherit"
            size="small"
            href="https://www.tiktok.com/@tecnoalexis?is_from_webapp=1&sender_device=pc"
            aria-label="TikTok"
            target='_blank'
            sx={{ alignSelf: 'center' }}
            title='Ir a TikTok'
          >
            <IoLogoTiktok />
          </IconButton>

          <IconButton
            color="inherit"
            size="small"
            href="https://www.instagram.com/tecnoalexiss"
            aria-label="Instagram"
            target='_blank'
            sx={{ alignSelf: 'center' }}
            title='Ir a Instagram'
          >
            <IoLogoInstagram />
          </IconButton>

          <IconButton
            color="inherit"
            size="small"
            href="https://www.facebook.com/profile.php?id=61551771311626&locale=es_LA"
            target='_blank'
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
            title='Ir a Facebook'
          >
            <IoLogoFacebook />
          </IconButton>

        </Stack>
      </Box>
    </Container>
  );
}

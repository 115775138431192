import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Pricing() {
  return (
    <Box
      id="sesion_precio"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        bgcolor: '#ECFAF3'
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{ color: 'text.primary', fontSize: { sm: 42, xs: 27 }, marginTop: { sm: 0, xs: 8 } }}
            style={{ fontFamily: 'roboto' }}
          >
            Configuración e Implementación de Correos Corporativos
          </Typography>
          
          <Typography sx={{ fontSize: { sm: 42, xs: 27 } }} style={{ fontFamily: 'roboto', fontWeight: '600', color: '#5025D1', marginTop: 20 }}>
            <span style={{ color: '#000'}}>Desde </span> $180,000 COP
          </Typography>

          <Typography variant="body1" sx={{ color: 'text.secondary' }} style={{ fontFamily: 'roboto', fontSize: 18, marginTop: 20 }}>
            Optimiza la comunicación de tu empresa con correos personalizados. Este valor no incluye el costo del dominio.
          </Typography>

          <Typography variant="body1" sx={{ color: '#000' }} style={{ fontFamily: 'roboto', fontSize: 14, marginTop: 40 }}>
            Para más información sin costo alguno, déjanos tus datos de contacto y te solucionaremos tus dudas.
          </Typography>
        </Box>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          useFlexGap
          sx={{ width: { xs: '100%', sm: '350px' } }}
          style={{ fontFamily: 'roboto', alignItems: 'center', alignContent: 'center', display: 'flex'}}
        >
          <Link
            to={'https://forms.zohopublic.com/alexisu/form/SoftwareRequest/formperma/gAVaKo8IXLnJPqlFwkQlP0ofk_3sumTU7X0xEqo9RF8'}
          >
            <Button
              sx={{ minWidth: 'fit-content' }}
              style={{ fontFamily: 'roboto', width: 300, backgroundColor: '#5025D1', color: '#fff' }}
            >
              Solicitar información
            </Button>    
          </Link>
        </Stack> 
      </Container>
    </Box>
  );
}

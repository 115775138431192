import React from 'react';
import LayoutPrincipal from '../../components/layouts/LayoutPrincipal';
import CardElectronica from '../../components/ui/cardContenido/CardElectronica'
import imgLm317 from '../../img/miniatura_lm317.jpg';
import imgSumador from '../../img/img_sumador.png';
import miniaturaComparador from '../../img/miniaturas_comparador.png';
import CardTexto from '../../components/ui/cardContenido/CardTexto';

// Data de la sesión de electrónica
const dataNovedades = [
  {
    id: 'ezXyNKGCy',
    imgPortada: miniaturaComparador,
    title: 'Amplificador Operacional - Comparador de Tensión',
    description: 'En este video les doy una explicación de como funciona el amplificador operacional - comparador de tensión, datos a tener en cuenta y escenarios en los que se utilizan estos componentes electrónicos.',
    date: '15 de Abril, 2024',
    to: 'https://www.youtube.com/watch?v=B3bOvdxYGak',
    solotext: false,
    contenido : [
      {
        title: 'Amplificador operacional comparador de tensión',
        data: 'Un amplificador operacional comparador de tensión es un circuito muy común en electrónica. Este se utiliza para comparar dos voltajes y determinar cuál es mayor.'
      },
      {
        title: 'Funcionamiento básico del comparador',
        data: 'El componente cuenta con dos entradas, una inversora (-) y una no inversora (+) y a su vez tiene una salida que va en función de la comparación de las dos entradas de voltaje. '
      },
      {
        title: '1. Primera condición',
        data: 'Si el voltaje en la entrada no inversora (+) es mayor al voltaje de la entrada inversora (-) entonces la salida del amplificador operacional será sercano al valor de alimentación positiva del amplificador.'
      },
      {
        title: '2. Segunda condición',
        data: 'Si el voltaje en la entrada inversora (-) es mayor al voltaje de la entrada no inversora (+) entonces la salida del amplificador operacional será sercano al valor de alimentación negativa o al valor más bajo del amplificador.'
      },
      {
        title: '3. Tercera condición',
        data: 'Si el voltaje en la entrada no inversora (+) es exactamente igual al voltaje de la entrada inversora (-) entonces la salida del amplificador operacional será (cero). \n Pero este caso no es muy común verlo ya que las dos señales que se comparen deben de ser idénticas.'
      }
    ]
  },
  {
    id: 'eIv5He87J',
    imgPortada: imgLm317,
    title: 'Cálculos teóricos para el componente electrónico LM317T',
    description: 'En este video les explico como hacer el cálculo para las resistencias de la configuración de un LM317T como regulador de voltaje.',
    date: '27 Enero, 2024',
    to: 'https://www.youtube.com/watch?v=U2yXcu5zpdU',
    solotext: false,
    contenido : [
      {
        title: 'Regulador de voltaje LM317',
        data: 'El LM317 es un regulador de voltaje ajustable bastante utilizado en circuitos electrónicos con el que se obtiene un voltaje de salida estable y regulable. \n \n Este componente es especialmente útil en aplicaciones donde se necesita un voltaje específico que no se puede obtener directamente de una fuente de alimentación común.'
      },
      {
        title: 'Características del LM317',
        data: 'Voltaje de salida: Este regulador permite ajustar un voltaje desde 1.25V hasta 37V pero esto depende de la configuración con las resistencias en el circuito. \n \n Corriente de salidad máxima: Este componente puede suministrar hasta un máximo de 1.5A pero esto depende de la disipación del calor y el voltaje de entrada. \n \n Protección Interna: Incluye protecciones contra sobrecarga, sobrecalentamiento y corriente excesiva, lo que lo hace robusto y confiable.'
      }
    ]
  },
  {
    id: 'eJqAQCXQG',
    imgPortada: imgSumador,
    title: 'Amplificador Operacional - Sumador de Tensión',
    description: 'En este video les explico como obtener la ecuación del amplificador operacional - sumador de tensión.',
    date: '28 Febrero, 2024',
    to: 'https://www.youtube.com/watch?v=NZU2L3dghgQ',
    solotext: false,
    contenido : [
      {
        title: 'Amplificador operacional sumador de tensión',
        data: 'Un amplificador operacional configurado como sumador de tensión es un circuito que suma varias señales de entrada, este produce una señal de salida que es proporcional a la suma ponderada de las señales de entrada. \n \n A este circuito se le conoce como sumador inversor o amplificador sumador.'
      },
      {
        title: 'Funcionamiento',
        data: 'Este circuito suma las señales de entrada aplicada através de un arrego de resistencias y la salida va a ser la suma amplificada invertida de estas señales.'
      }
    ]
  },
]

const Home = () => {
  return (
    <>
      <LayoutPrincipal titlePage='Novedades'>
        <div style={{ width: '100%', height: '100%'}}>
        { dataNovedades.map((c, index) => (
          c.solotext ? <CardTexto path={ '/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } body={ c.body } to={ c.to } alt={ c.title } contenido={ c.contenido }/> : 
          <CardElectronica path={ '/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } imgPortada={ c.imgPortada } to={ c.to } alt={ c.title } contenido={ c.contenido }/>
        ))}
      </div>
      </LayoutPrincipal>
    </>
  )
}

export default Home;
import React from 'react'
import MenuUi from './MenuUi';
import { Drawer } from 'antd';

const DrawerMenu = ({ clasname_, onClose, open}) => {

    return (
        <Drawer
            className={ clasname_ }
            width={ 256 }
            placement={'left'}
            closable={ false }
            onClose={ onClose }
            open={ open }
            key={'left'}
        >
            <div className='contDrawer'>

                <MenuUi clasname_='menuLateralDerechoDrawer'/>

                <div className='divFooterMenu'>
                    <h2 className='titleFooterMenu'>© 2024 TecnoAlexis Todos los derechos reservados</h2>
                </div>
            </div>
        </Drawer>
    )
}

export default DrawerMenu
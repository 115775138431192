import React from 'react'
import LayoutPrincipal from '../../components/layouts/LayoutPrincipal';
import CardElectronica from '../../components/ui/cardContenido/CardElectronica';
import imgNoInversor from '../../img/img_no_inversor.png';
import imgSumador from '../../img/img_sumador.png';
import miniaturaComparador from '../../img/miniaturas_comparador.png';
import CardTexto from '../../components/ui/cardContenido/CardTexto';

// Data de la sesión de electrónica
const dataTutoElectronica = [
  {
    id: 'ezXyNKGCy',
    imgPortada: miniaturaComparador,
    title: 'Amplificador Operacional - Comparador de Tensión',
    description: 'En este video les doy una explicación de como funciona el amplificador operacional - comparador de tensión, datos a tener en cuenta y escenarios en los que se utilizan estos componentes electrónicos.',
    date: '15 de Abril, 2024',
    to: 'https://www.youtube.com/watch?v=B3bOvdxYGak',
    solotext: false,
    contenido : [
      {
        title: 'Amplificador operacional comparador de tensión',
        data: 'Un amplificador operacional comparador de tensión es un circuito muy común en electrónica. Este se utiliza para comparar dos voltajes y determinar cuál es mayor.'
      },
      {
        title: 'Funcionamiento básico del comparador',
        data: 'El componente cuenta con dos entradas, una inversora (-) y una no inversora (+) y a su vez tiene una salida que va en función de la comparación de las dos entradas de voltaje. '
      },
      {
        title: '1. Primera condición',
        data: 'Si el voltaje en la entrada no inversora (+) es mayor al voltaje de la entrada inversora (-) entonces la salida del amplificador operacional será sercano al valor de alimentación positiva del amplificador.'
      },
      {
        title: '2. Segunda condición',
        data: 'Si el voltaje en la entrada inversora (-) es mayor al voltaje de la entrada no inversora (+) entonces la salida del amplificador operacional será sercano al valor de alimentación negativa o al valor más bajo del amplificador.'
      },
      {
        title: '3. Tercera condición',
        data: 'Si el voltaje en la entrada no inversora (+) es exactamente igual al voltaje de la entrada inversora (-) entonces la salida del amplificador operacional será (cero). \n Pero este caso no es muy común verlo ya que las dos señales que se comparen deben de ser idénticas.'
      }
    ]
  },/*
  {
    id: 'asas76s5665ds3476dsdc93l',
    imgPortada: '',
    title: 'Amplificador Operacional - Restador',
    description: 'En este video les explico como obtener la ecuación del amplificador operacional - sumador de tensión.',
    body: 'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.',
    date: '08 Julio, 2024',
    to: 'https://www.youtube.com/watch?v=NZU2L3dghgQ',
    solotext: true,
    contenido : [
    ]
  }, */
  {
    id: 'et5j7Xnne',
    imgPortada: imgNoInversor,
    title: 'Cálculos teóricos para el Amplificador Operacional - No inversor',
    description: 'En este video les explico cómo obtener la ecuación del amplificador operacional - no inversor, como la amplitud o ganancia a partir de las resistencias R1 y R2.',
    date: '16 Febrero, 2024',
    to: 'https://www.youtube.com/watch?v=Jf8-WtPtnGg',
    solotext: false,
    contenido : [
      {
        title: 'Amplificador operacional no inversor',
        data: 'El amplificador operacional configurado como no inversor, no es más que un circuito en el que la señal se aplica a la entrado no inversora del amplificador y su salida ya amplificada tiene la misma polaridad de la señal que se ingreso. \n \n Su ganancia o amplitud siempre es positiva y la señal de salida está en fase con la señal de entrada'
      }
    ]
  }, /*
  {
    id: 'asas76s5665ds3476dsdc96l',
    imgPortada: '',
    title: 'Amplificador Operacional - Restador',
    description: 'En este video les explico como obtener la ecuación del amplificador operacional - sumador de tensión.',
    body: 'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.',
    date: '08 Julio, 2024',
    to: 'https://www.youtube.com/watch?v=NZU2L3dghgQ',
    solotext: true,
    contenido : [
    ]
  }, */
  {
    id: 'eJqAQCXQG',
    imgPortada: imgSumador,
    title: 'Amplificador Operacional - Sumador de Tensión',
    description: 'En este video les explico como obtener la ecuación del amplificador operacional - sumador de tensión.',
    date: '28 Febrero, 2024',
    to: 'https://www.youtube.com/watch?v=NZU2L3dghgQ',
    solotext: false,
    contenido : [
      {
        title: 'Amplificador operacional sumador de tensión',
        data: 'Un amplificador operacional configurado como sumador de tensión es un circuito que suma varias señales de entrada, este produce una señal de salida que es proporcional a la suma ponderada de las señales de entrada. \n \n A este circuito se le conoce como sumador inversor o amplificador sumador.'
      },
      {
        title: 'Funcionamiento',
        data: 'Este circuito suma las señales de entrada aplicada através de un arrego de resistencias y la salida va a ser la suma amplificada invertida de estas señales.'
      }
    ]
  }
]

const AmplificadoresOperacionales = () => {
  return (
    <LayoutPrincipal titlePage='Amplificadores Operacionales'>
      <div style={{ width: '100%', height: '100%'}}>
        { dataTutoElectronica.map((c, index) => (
          c.solotext ? <CardTexto path={ '/amplificador-operacional/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } body={ c.body } to={ c.to } alt={ c.title } contenido={ c.contenido }/> : 
          <CardElectronica path={ '/amplificador-operacional/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } imgPortada={ c.imgPortada } to={ c.to } alt={ c.title } contenido={ c.contenido }/>
        ))}
      </div>
    </LayoutPrincipal>
  )
}

export default AmplificadoresOperacionales;
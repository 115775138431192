import React from 'react'
import LayoutPrincipal from '../../components/layouts/LayoutPrincipal';
import CardElectronica from '../../components/ui/cardContenido/CardElectronica';
import imgPdfExcel from '../../img/pdf_excel.jpg';
import CardTexto from '../../components/ui/cardContenido/CardTexto';

// Data de la sesión de electrónica
const dataDesarrollo = [
  {
    id: 'j6s4udnd123n2j7985nfdlf5',
    imgPortada: imgPdfExcel,
    title: 'Como exportar datos de un JSON a Excel desde React js',
    description: 'Hola Amigos, en este video les muestro un ejemplo básico de como podemos exportar datos desde un JSON a Excel con React js ',
    date: '26 Noviembre, 2023',
    to: 'https://www.youtube.com/watch?v=xOnoiRNwRm4',
    solotext: false,
    contenido : [
    ]
  },
]

const ExcelReact = () => {
  return (
    <LayoutPrincipal titlePage='Excel en React'>
      <div style={{ width: '100%', height: '100%'}}>
        { dataDesarrollo.map((c, index) => (
          c.solotext ? <CardTexto path={ '/excel-react/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } body={ c.body } to={ c.to } alt={ c.title } contenido={ c.contenido }/> : 
          <CardElectronica path={ '/excel-react/contenido/' + c.id } id={ c.id } title={ c.title } description={ c.description } date={ c.date } imgPortada={ c.imgPortada } to={ c.to } alt={ c.title } contenido={ c.contenido }/>
        ))}
      </div>
    </LayoutPrincipal>
  )
}

export default ExcelReact;
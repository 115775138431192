import React, { useState } from 'react';
import './styleCardElectronica.css';
import { IoLogoYoutube, IoEllipsisHorizontal } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { Popover } from 'antd';

const CardElectronica = ({ id, title, description, date, imgPortada, alt, to, path, contenido }) => {

    const [open, setOpen] = useState( false );

  
    const handleOpenChange = ( newOpen ) => {
        setOpen( newOpen );
    };

    return (
        <Link to={ path } state={{ id, title, description, date, imgPortada, alt, to, contenido }}>
            <div key={ id } className='divPadreCardElectronica'>
                <div className='divContainerContElectronica'>
                    <div className='divHeaderCardElectronica'>
                        <h1 className='h1DateElectronica'>{ date }</h1>
                        <div>
                            <div className='popover1'>
                                <Popover placement="bottomRight" content={ <div className='divContentPopover'>{ description }</div> } className='popoverCardElectronica'> 
                                    <IoEllipsisHorizontal style={{ fontSize: '25px', color: '#000'}}/>
                                </Popover>
                            </div>

                            <div className='popover2'>
                                <Popover placement="bottomRight" trigger="click" open={ open } onOpenChange={ handleOpenChange } content={ <div className='divContentPopover'>{ description }</div> } className='popoverCardElectronica'> 
                                    <IoEllipsisHorizontal style={{ fontSize: '25px', color: '#000'}}/>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className='divTitleCardElectronica'>
                        <h1 className='h1TitleElectronica'>{ title }</h1>
                    </div>

                    <div className='divContainerImagenElectronica'>
                        <img className='imgCardElectronica' src={ imgPortada } alt={ alt } class="w-full object-cover"/>
                    </div>

                    <div className='divContainerCardBotonesElectronica'>
                        <div className='divContainerBtnYutuCardElectronica'>
                            <Link to={ to } target='_blank' title='Ir a YouTube'>
                                <div className='divBtnYoutuCardElectronica'>
                                    <IoLogoYoutube style={{ color: '#F53855', fontSize: '28px' }}/>
                                    <h1 className='h1TitelYoutuElectronica'>Ir a YouTube</h1>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default CardElectronica;
import React from 'react';
import BodyLinks from '../../components/ui/BodyLinks';

const Links = () => {
  return (
    <>
        <BodyLinks />
    </>
  )
}

export default Links;
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      id="sesion_preguntas_frec"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 10, sm: 20 },
        bgcolor: '#B6EEFE'
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
          marginTop: { sm: 0, xs: 8 }
        }}
        style={{ fontFamily: 'roboto', fontSize: 30 }}
      >
        Preguntas Frecuentes
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2" style={{ fontFamily: 'roboto'}}>
              ¿Qué es un correo empresarial?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '80%' } }}
              style={{ fontFamily: 'roboto', lineHeight: 1.8 }}
            >
              El correo empresarial es una cuenta de correo electrónico que se utiliza explícitamente para comunicaciones empresariales o profesionales. Un correo empresarial está diseñado exclusivamente para equipos y organizaciones que realizan comunicaciones empresariales. Se configura con el dominio propio que utiliza la organización y agrega credibilidad y profesionalismo a la marca. Las funciones como las direcciones de correo electrónico de grupo y las listas globales de direcciones hacen que la colaboración sea más eficaz.
            </Typography>
          </AccordionDetails>
        </Accordion>
        
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2" style={{ fontFamily: 'roboto'}}>
              ¿En qué se diferencia el correo empresarial del correo electrónico personal?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '80%' } }}
              style={{ fontFamily: 'roboto', lineHeight: 1.8 }}
            >
              El correo electrónico personal es ideal para intercambios privados con amigos y familiares. También se puede utilizar para registrarse en servicios personales y cuentas personales de redes sociales. El correo empresarial es mejor para administrar las comunicaciones empresariales y los contactos profesionales. En general, el correo empresarial viene con alias de grupos, mayor almacenamiento y funciones e integraciones específicas de la empresa, incluido el soporte de migración y el panel de control.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2" style={{ fontFamily: 'roboto'}}>
              ¿Mis datos están seguros?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '80%' } }}
              style={{ fontFamily: 'roboto', lineHeight: 1.8 }}
            >
              Nos apasiona mantener sus datos protegidos y resguardados. Las instalaciones de zoho cuentan con una estricta seguridad ininterrumpida a través de monitoreo por video, acceso biométrico y sistemas avanzados de vigilancia ante robos, inundaciones e incendios. Nuestro sistema de seguridad de red utiliza las tecnologías más recientes de encriptado, así como de prevención y detección de intrusos.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2" style={{ fontFamily: 'roboto'}}>
              ¿Qué es el hosting de correo electrónico?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '80%' } }}
              style={{ fontFamily: 'roboto', lineHeight: 1.8 }}
            >
              El hosting de correo electrónico es un servicio que ejecuta servidores de correo electrónico y proporciona servicios de correo electrónico a individuos y empresas. Permite a los usuarios enviar y recibir correos electrónicos mediante sus cuentas de correo electrónico con dominio propio. El servicio de hosting de correo electrónico ofrece un acceso seguro a sus datos guardados, además de ofrecer filtros para no recibir correos no deseados ni virus.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2" style={{ fontFamily: 'roboto'}}>
              ¿Cuáles son las ventajas de utilizar un servicio de hosting de correo electrónico en comparación con el uso de servidores de correo electrónico personal?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '80%' } }}
              style={{ fontFamily: 'roboto', lineHeight: 1.8 }}
            >
              Administrar sus propios servidores de correo electrónico y protegerlos de correos no deseados y ataques DDOS es difícil y consume mucho tiempo. Cuando configura su dominio con un host de correo electrónico, este se encarga de estas complejas tareas, una vez que se realiza la configuración inicial. Gracias a las amplias opciones de personalización, delegar al host de correo electrónico el uso del correo electrónico del dominio lo ayudará a centrarse en su empresa en lugar de en el funcionamiento de los servidores.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      </Container>
    </Box>
  );
}

import React from 'react'
import LayoutPrincipal from '../../components/layouts/LayoutPrincipal';
import CardElectronica from '../../components/ui/cardContenido/CardElectronica';
import imgNidos from '../../img/miniatura_nidos.png';

// Data de la sesión de electrónica
const dataGranja = [
  {
    id: 'gkR7RyLPH',
    imgPortada: imgNidos,
    title: 'Nidos para gallinas ponedoras de fácil recolección de huevos',
    description: 'Les enseño una idea de unos nidos para gallinas ponedores en el que la recolección de huevos es mucho más eficiente para gallinas de libre pastoreo.',
    date: '9 Abril, 2024',
    to: 'https://www.youtube.com/watch?v=x_kK8h-pMzE&t',
    solotext: false,
    contenido : [
      {
        title: 'Nidos de fácil recolección de huevos para gallinas ponedoras',
        data: ''
      },
      {
        title: '',
        data: ''
      }
    ]
  },
]

const GallinasPonedoras = () => {
  return (
    <LayoutPrincipal titlePage='Gallinas Ponedoras'>
      <div style={{ width: '100%', height: '100%'}}>
        { dataGranja.map((c, index) => (
          <CardElectronica title={ c.title } description={ c.description } date={ c.date } imgPortada={ c.imgPortada } to={ c.to } alt={ c.title }/>
        ))}
      </div>
    </LayoutPrincipal>
  )
}

export default GallinasPonedoras;